import React from "react";
import "./banner.css";
import { Link, useLocation } from "react-router-dom";
// import coronaImg from "../../assets/Mega Menu Icons.svg";
import menuIcons2 from "../../assets/Mega Menu Icons (1).svg";
import { openInNewTab } from "../../util/outsideAlert";

const Banner = () => {
  const location = useLocation(); // Get the current route

  // Only show the description on /checkout and /cart pages
  const showDescription =
    location.pathname === "/checkout" || location.pathname === "/cart";

  return (
    <div className='banner'>
      <div className='cta'>
        {showDescription && (
          <div className='description'>Free Shipping Above $200 in Ontario</div>
        )}
        <div className='banner-left-wrap'>
          <img src={menuIcons2} alt='img' className='banner-icon' />
          <span className='banner-text'>Quick and reliable care for</span>
          <Link
            to='#'
            onClick={() =>
              openInNewTab("https://bookmytest.ca/services/5198246445")
            }
            className='covid-link'>
            Minor Ailments
          </Link>
        </div>

        <div className='banner-right-wrap'>
          <Link to='/Contact' className='new-link-banner'>
            Contact us
          </Link>
          <Link to='/auth/login' className='new-link-banner'>
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Banner;
