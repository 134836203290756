import React, { useState, useEffect } from "react";
import "./Cart.scss";
import Navbar from "../../components/Navbar/Navbar";
import Banner from "../../components/banner/Banner";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import cartShipping from "../../assets/cartShipping.svg";
import TableWrapper from "../../components/Table/Table";
import { tableFive } from "../../tableSettings";
import emptyCart from "../../assets/tabler-icon-shopping-cart-off.svg";
import { AiOutlineHome } from "react-icons/ai";
import Dropdown from "../../components/Navbar/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { decrementQuantity, incrementQuantity, removeItem, saveCoupon } from "../../Redux/cartSlice";
import { Helmet } from "react-helmet";
import { domain } from "../../config";
import { publicRequest } from "../../util/ApiRequests";
import { Alert } from "@mui/material";
import { FaRegTrashAlt } from "react-icons/fa";

const Cart = () => {
  const cart = useSelector((state) => state.cart.products);
  const storedCoupon = useSelector((state) => state.cart.coupon);
  const dispatch = useDispatch();
  const [totalCartP, setTotalcartP] = useState(0);
  const [error, setError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);
  const [appliedCoupon, setAppliedCoupon] = useState(null);

  const handleDecrement = (item) => {
    dispatch(decrementQuantity(item));
  };

  const handleIncrement = (item) => {
    dispatch(incrementQuantity(item));
  };

  const handleRemove = (item) => {
    dispatch(removeItem(item));
  };

  const handleRemoveCoupon = () => {
    setDiscount(0);
    setAppliedCoupon(null);
    setCoupon("");
    dispatch(saveCoupon(null));
  };

  useEffect(() => {
    if (storedCoupon) {
      setCoupon(storedCoupon.code);
      setDiscount(parseFloat(storedCoupon.amount));
      setAppliedCoupon(storedCoupon);
    }
  }, [storedCoupon]);

  useEffect(() => {
    const getTotalCart = () => {
      if (cart.length) {
        const totalAmount = cart.reduce(
          (prev, current) =>
            prev +
            current.quantity *
              (current.on_sale ? current.sale_price : current.price),
          0
        );
        setTotalcartP(Math.round(totalAmount * 100) / 100);
      }
    };
    getTotalCart();
  }, [cart]);

  const handleApplyCoupon = async () => {
    try {
      const response = await publicRequest('get', `/coupons?code=${coupon}`);
      const data = response.data;
      if (data.length > 0 && data[0].discount_type === "percent") {
        setDiscount(parseFloat(data[0].amount));
        setAppliedCoupon(data[0]);
        dispatch(saveCoupon(data[0]));
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 5000);
      } else {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 5000);
      }
    } catch (err) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  };

  return (
    <div className="cart">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cart </title>
        <link rel="canonical" href={`${domain}/cart`} />
      </Helmet>
      <Banner />
      <Navbar />
      <Dropdown />
      <div className="cart-wrapper">
        {cart.length !== 0 ? (
          <div className="cart-details">
            <div className="cart-main-container">
              <div className="current-path-link">
                <div className="links">
                  <Link
                    to="/"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AiOutlineHome
                      style={{
                        fontSize: "22px",
                        paddingRight: "8px",
                      }}
                    />
                    Home
                  </Link>
                  <BsChevronRight />
                  <Link to="/categories">Shop</Link>
                  <BsChevronRight />
                  <Link to="/cart">cart</Link>
                </div>
                <div className="rightsection">
                  <div className="shipping-info">
                    <img src={cartShipping} alt="icon" />
                    <h1>Shipping options will be updated during checkout.</h1>
                  </div>
                  <div className="teaser">
                    {totalCartP > 200 ? <span>Free Shipping Applied</span>:<span> ${Number(200 - totalCartP).toFixed(2)} Left For Free Shipping</span> }
                  </div>
                </div>
              </div>
              <div className="cart-summary">
                <TableWrapper
                  data={cart}
                  columns={tableFive}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  handleRemove={handleRemove}
                  cut={true}
                />
              </div>
            </div>
            <div className="coupon-div">
              <div className="coupon-input">
                <input
                  placeholder="Enter coupon code"
                  type="text"
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                />
                <button className="apply-coupon" onClick={handleApplyCoupon}>
                  Apply Coupon
                </button>
              </div>
              {appliedCoupon && (
                <div className="applied-coupon">
                  <span className="coupon-code">
                    {appliedCoupon.code} ({appliedCoupon.amount}%)
                  </span>
                  <button className="remove-coupon" onClick={handleRemoveCoupon}>
                    <FaRegTrashAlt />
                  </button>
                </div>
              )}
              <Link to="/checkout" className="checkout-button">
                <span>Continue to Checkout</span>
                <div className="line"></div>
                <span className="total">
                  ${Math.round((totalCartP - (totalCartP * discount) / 100) * 100) / 100}
                </span>
              </Link>
            </div>
          </div>
        ) : (
          <div className="empty-cart">
            <img src={emptyCart} alt="empty-cartIcon" />
            <div className="text-wrapper">
              <span className="textOne">No products yet!</span>
              <span className="slogan">
                Visit the shop page and buy supplements of your choice!
              </span>
            </div>
            <Link to="/categories" className="shop-button">
              Go to Shop
            </Link>
          </div>
        )}
      </div>
      <Footer />
      {error && (
        <Alert
          severity="error"
          style={{
            width: "fit-content",
            position: "fixed",
            top: "20px",
            right: "20px",
            zIndex: "50",
          }}
        >
          Error: Unable to apply coupon!
        </Alert>
      )}
      {showAlert && (
        <Alert
          style={{
            width: "fit-content",
            position: "fixed",
            top: "10px",
            right: "10px",
            zIndex: "50",
          }}
          severity="success"
        >
          Coupon applied successfully!
        </Alert>
      )}
    </div>
  );
};

export default Cart;
